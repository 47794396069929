<template>
  <div class="promotion-7a-con">
    <div style="margin-bottom: -160px;">
      <img src="./imgs/b1.png" alt="" />
    </div>

    <div>
      <img src="./imgs/b2.png" alt="" />
      <img src="./imgs/b3.png" alt="" />
      <img src="./imgs/b4.png" alt="" />
      <img src="./imgs/b5.png" alt="" />
    </div>

    <div style=" text-align: center;color: white;line-height: 1.7;font-size: 14px;">
      <p>APP开发者：XXXXX网络科技有限公司</p>
      <p>红狮 | 应用版本5.0.0 | 应用权限 | 隐私权限</p>
      <p>温馨提示：市场有风险，投资需谨慎</p>
    </div>

    <div
      style="margin-top: 12px;height: 60px;background: #00000088; display: flex;justify-content: center;"
    >
      <img src="./imgs/download.gif" alt="" />
    </div>
  </div>
</template>

<script>
import eventBusMitter from '../../../../utils/eventbus';
eventBusMitter.emit('eventBus', { key: true });

export default {};
</script>

<style lang="scss" scoped>
.promotion-7a-con {
  img {
    width: 100%;
    display: block;
  }
  background: #ef244f;
}
</style>
